import Vuex from 'vuex'
import { GetLocalTokenInfo, IsBlank, IsReAuthLogin, NewLocalToken, GetBrowserType } from '../utils/tools/index'
import { requestGetUserBaseInfo } from '../api/api'

export default new Vuex.Store(
  {
    state: {
      appname: '',
      token: '',
      accesstoken: '', // 服务器端的token
      isgettoken: false, // 是否获取了token信息
      openid: '', // 这里会等于accesstoken为了兼容性
      name: '',
      outid: '',
      wxinfo: '',
      validdt: '', // 有效期，超过了时间需要再次申请openid
      isbind: false, // 是否绑定一卡通信息
      ishasuser: false, // 是否有基本信息了
      loginstatus: 0, // 状态信息，0是默认的啥也没有，1有localtoken没有送服务器，2送了服务器也有返回信息
      typekey: 'wx', // wx,qywx,dd,other
      flag: '',
      userinfo: [],
      isneedauthlogin: true,
      bgpic: '', // 个人查询的背景图片
      headpic: '', // 个人的头像
      beian: '',
      isenablewxpay: 0,
      isenablealipay: 0,
      getopenid: 0
    },
    mutations: {
      updateWxInfo (state, wxinfo) {
        console.log('开始更新wxS数据')
        // // window.alert('开始更新wxS数据...' + wxinfo.accesstokendt)
        console.log(wxinfo)
        state.wxinfo = wxinfo
        state.accesstoken = wxinfo.accesstoken
        state.accesstokendt = wxinfo.accesstokendt
        state.openid = wxinfo.openid
        // state.accesstokendt = '2022-10-1'
        if (IsBlank(state.accesstoken)) state.accesstoken = state.openid
        console.log(state.accesstoken)
        localStorage.setItem('accesstoken', state.accesstoken)
        localStorage.setItem('accesstokendt', state.accesstokendt)
      },
      updateUserInfo (state, userinfo) {
        console.log('开始更新wuserinfo数据')
        state.userinfo = userinfo
        sessionStorage.setItem('user', userinfo)
        // 如果底图和头像有变化，就更新一下
        if (!IsBlank(userinfo.bgpic)) state.bgpic = userinfo.bgpic
        if (!IsBlank(userinfo.headpic)) state.headpic = userinfo.headpic
      },
      UpdateTokenInfo (state, accesstoken) {
        state.accesstoken = accesstoken
        localStorage.setItem('accesstoken', accesstoken)
        localStorage.setItem('openid', accesstoken)
        sessionStorage.setItem('accesstoken', accesstoken)
        sessionStorage.setItem('openid', accesstoken)
      },
      UpdateHasOpenidInfo (state, getopenid) {
        state.getopenid = getopenid
        localStorage.setItem('getopenid', getopenid)
      },
      UpdateOpenid (state, openid) {
        localStorage.setItem('openid', openid)
        sessionStorage.setItem('openid', openid)
        localStorage.setItem('accesstoken', openid)
        sessionStorage.setItem('accesstoken', openid)
        state.openid = openid
      },
      UnBindInfo (state, token) {
        // state.token = ''
        state.accesstoken = ''
        state.openid = ''
        state.isgettoken = false
        state.outid = ''
        state.name = ''
        state.wxinfo = ''
        
        console.log(token)
  
        localStorage.setItem('openid', state.accesstoken)
        sessionStorage.setItem('openid', state.accesstoken)
        localStorage.setItem('accesstoken', state.accesstoken)
        sessionStorage.setItem('accesstoken', state.accesstoken)
      },
      getIsNeedAuthLogin (state) {
        return IsReAuthLogin(state)
      },
      getBrowseType (state) {
        var ua = window.navigator.userAgent.toLowerCase()
        // // window.alert(ua)
        if ((ua.match(/MicroMessenger/i) === 'micromessenger') && (ua.match(/wxwork/i) === 'wxwork')) {
          // // window.alert('企业微信客户端')
          state.typekey = 'qywx'
          return 1
        } else if (ua.match(/micromessenger/i) === 'micromessenger') {
          // // window.alert('微信客户端')
          state.typekey = 'wx'
          return 0
        } else if (/AlipayClient/.test(window.navigator.userAgent)) {
          state.typekey = 'alipay'
          return 2
        } else return -1
      }
    },
    getters: {
      openidex: state => state.token + state.accesstoken,
      messageTrashCount: state => state.messageTrashList.length,
      username: state => state.userName,
      userinfo: state => state.userinfo,
      access: state => state.access,
      hasopenid: state => !IsBlank(state.accesstoken),
      hasbind: state => state.userInfo.length > 0,
      isReAuthLogin: state => state.isneedauthlogin,
      isGrid: state => (state.userinfo.homestyle === '1' || state.userinfo.homestyle === 1),
      token: state=> state.token
    },
    actions: {
      async getBaseUserInfo({state}) {
        console.log(state)
        const res = await requestGetUserBaseInfo({accesstoken: state.accesstoken})
        // console.log(res)
        if (res.return_code === '0')
        {
          state.userinfo = res.data.userinfo
          sessionStorage.setItem('user', state.userinfo)
          return res
        } else return res

      },
      async BindUserInfo({state}) {
        console.log(state)
        const res = await requestGetUserBaseInfo({accesstoken: state.accesstoken})
        console.log(res)
        if (res.return_code === '0')
        {
          state.userinfo = res.data.userinfo
          sessionStorage.setItem('user', state.userinfo)
        } else return res

      },
      // 登录
      getLocalLoginInfo ({state}) {
        console.log('action getlocalogininfo')
        GetLocalTokenInfo(state)
        let typekey = GetBrowserType();
        if (typekey === 0 ) state.typekey = 'wechat'
        else if (typekey === 1 ) state.typekey = 'qywx'
        else if (typekey === 2 ) state.typekey = 'alipay'
        else state.type =''

        console.log(state)
      },
      ChangeToken ({state}) {
        console.log('action ChangeToken')
        NewLocalToken(state)
      },
      clearAccessToken ({state}) {
        state.accesstoken = ''
        state.openid = ''
        localStorage.setItem('accesstoken', state.accesstoken)
        sessionStorage.setItem('accesstoken', state.accesstoken)
        localStorage.setItem('openid', state.accesstoken)
        sessionStorage.setItem('openid', state.accesstoken)
      },
      getIsNeedAuthLogin (state) {
        const res = IsReAuthLogin(state.state)
        console.log('store 里面获取的登录状态：', res)
        console.log(IsReAuthLogin(state.state))
        // console.log('获取的登录状态是：', state.state.isneedauthlogin)
      }
    }
  },
);
