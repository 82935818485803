import axios from 'axios'
// let base = 'http://192.168.0.101:8082' // 本地同一局域网
// let base='http://10.0.0.107:8082'
// let base = 'http://newcap.cc:16006' // 本地同一局域网
let base = ''
// let base = 'http://192.168.0.110:8082' // 本地同一局域网
// let base = 'http://10.0.0.107:8082' // 本地同一局域网
// let base = 'http://shyujiu.cn' // 本地同一局域网
export const requestGetPayUrl = params => { return axios.post(`${base}/pay/pay_geturl.do`, params).then(res => res.data) };
export const requestGetSysInfo = params => { return axios.post(`${base}/sys/getsysinfo`, params).then(res => res.data) };
export const requestGetUserBaseInfo = params => { return axios.post(`${base}/sys/getuserbaseinfo`, params).then(res => res.data) };
export const requestUserBind = params => { return axios.post(`${base}/sys/binduser`, params).then(res => res.data) };
export const requestUserUnBind = params => { return axios.post(`${base}/sys/unbinduser`, params).then(res => res.data) };
export const requestGetPayRecentInfo = params => { return axios.post(`${base}/sys/pay_getrecentinfo`, params).then(res => res.data) };
export const requestGetPayRecentListInfo = params => { return axios.post(`${base}/sys/pay_getrecentpayinfo`, params).then(res => res.data) };
export const requestGetConsumeList = params => { return axios.post(`${base}/sys/pay_consume_query`, params).then(res => res.data) };
export const requestGetSaveList = params => { return axios.post(`${base}/sys/pay_save_query`, params).then(res => res.data) };
export const requestPayDeleteOrder = params => { return axios.post(`${base}/sys/pay_delete_orderno`, params).then(res => res.data) };
export const requestUserLoss = params => { return axios.post(`${base}/sys/user_loss`, params).then(res => res.data) };
export const requestGetGrantTermList = params => { return axios.post(`${base}/sys/access_getgranttermlist`, params).then(res => res.data) };
export const requestGetAttendDetail = params => { return axios.post(`${base}/sys/attend_querydetail`, params).then(res => res.data) };
export const requestRemoteOpenDoor = params => { return axios.post(`${base}/sys/access_remoteopendoor`, params).then(res => res.data) };
export const requestRepairDoorGrant = params => { return axios.post(`${base}/sys/access_repairdoorgrant`, params).then(res => res.data) };
export const requestGetUserFunctionList = params => { return axios.post(`${base}/sys/user_getfunctionlist`, params).then(res => res.data) };
export const requestSaveUserFunctionList = params => { return axios.post(`${base}/sys/user_savefunctionlist`, params).then(res => res.data) };
export const requestUserModifyPwd = params => { return axios.post(`${base}/sys/modifyuserpwd`, params).then(res => res.data) };
export const requestLoginGetWxLoginUrl = params => { return axios.post(`${base}/login/getloginurl_wx`, params).then(res => res.data) };
export const requestLoginGetAliPayLoginUrl = params => { return axios.post(`${base}/login/getloginurl_alipay`, params).then(res => res.data) };

export const requestProcessFacePic = params => { return axios.post(`${base}/pic/uploadfacepic`, params).then(res => res.data) };