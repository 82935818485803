import { createRouter, createWebHashHistory } from 'vue-router';

const routes = [
  {
    name: 'index',
    path: '/index',
    component: () => import('./view/index/index'),
    meta: {
      title: '主页面',
    },
  },
  {
    path: '',
    redirect: '/index'
  },
  {
    path: "/:catchAll(.*)",
    redirect: '/index'
  },
  {
    name: 'error',
    path: '/error',
    component: () => import('./view/error'),
    meta: {
      title: '错误提醒',
    },
  },
  {
    name: 'user',
    path: '/user',
    component: () => import('./view/user'),
    meta: {
      title: '会员中心',
    },
  },
  {
    name: 'facepic',
    path: '/facepic',
    component: () => import('./view/facepic'),
    meta: {
      title: '照片采集',
    },
  },
  {
    name: 'bind',
    path: '/bind',
    component: () => import('./view/bind'),
    meta: {
      title: '绑定',
    },
  },
  {
    name: 'modifypwd',
    path: '/modifypwd',
    component: () => import('./view/user/modifypwd.vue'),
    meta: {
      title: '修改密码',
    },
  },
  {
    name: 'unbind',
    path: '/unbind',
    component: () => import('./view/bind/unbind.vue'),
    meta: {
      title: '解除绑定',
    },
  },
  {
    name: 'loss',
    path: '/loss',
    component: () => import('./view/loss'),
    meta: {
      title: '挂失',
    },
  },
  {
    name: 'consume',
    path: '/consume',
    component: () => import('./view/consume'),
    meta: {
      title: '查询消费',
    },
  },
  {
    name: 'save',
    path: '/save',
    component: () => import('./view/consume/save'),
    meta: {
      title: '查询充值',
    },
  },
  {
    name: 'payrecent',
    path: '/payrecent',
    component: () => import('./view/pay/payrecent'),
    meta: {
      title: '充值订单',
    },
  },  
  {
    name: 'wxpay',
    path: '/wxpay',
    component: () => import('./view/wxpay'),
    meta: {
      title: '微信原生充值',
    },
  }, 
  {
    name: 'alipay',
    path: '/alipay',
    component: () => import('./view/alipay'),
    meta: {
      title: '支付宝原生充值',
    },
  },
  {
    name: 'wxalipaysavedetail',
    path: '/wxalipaysavedetail',
    component: () => import('./view/wxpay/wxalipayrecent'),
    meta: {
      title: '原生充值订单',
    },
  },
  {
    name: 'attend',
    path: '/attend',
    component: () => import('./view/attend'),
    meta: {
      title: '考勤查询',
    },
  },
  {
    name: 'order',
    path: '/order',
    component: () => import('./view/cart/order.vue'),
    meta: {
      title: '确认订单',
    },
  },
  {
    name: 'goods',
    path: '/goods',
    component: () => import('./view/goods'),
    meta: {
      title: '商品详情',
    },
  },
  {
    name: 'pay',
    path: '/pay',
    component: () => import('./view/pay'),
    meta: {
      title: '在线充值',
    },
  },
  {
    name: 'access',
    path: '/access',
    component: () => import('./view/attend/remoteaccess'),
    meta: {
      title: '门禁功能',
    },
  },
  {
    name: 'orderlist',
    path: '/orderlist',
    component: () => import('./view/order'),
    meta: {
      title: '订单列表',
      keepAlive: false
    },
  },
  {
    name: 'shop',
    path: '/shop',
    component: () => import('./view/shop/index'),
    meta: {
      title: '本地商城',
      keepAlive: true
    },
  },
  {
    name: 'cashier',
    path: '/cashier',
    component: () => import('./view/cashier/index'),
    meta: {
      title: '收银台',
      keepAlive: true
    },
  },
  {
    name: 'user',
    path: '/user',
    component: () => import('./view/user/index'),
    meta: {
      title: '本地商城',
    },
  },
  {
    name: 'useroption',
    path: '/useroption',
    component: () => import('./view/user/option'),
    meta: {
      title: '用户设置',
    },
  },
];

const router = createRouter({
  routes,
  history: createWebHashHistory(),
});

router.beforeEach((to, from, next) => {
  const title = to.meta && to.meta.title;
  if (title) {
    document.title = title;
  }
  next();
});

export { router };
